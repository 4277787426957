import React, { KeyboardEvent } from 'react';
import { TextInput, TextInputProps } from '@volvo-cars/react-forms';
import { useTracker } from '@volvo-cars/tracking';
import { IconButton, IconName } from '@volvo-cars/react-icons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { TRACKING_CATEGORY } from '../../tracking';

type TextInputWithIconProps = TextInputProps & {
  icon: IconName;
  inputRef: React.RefObject<HTMLInputElement> | null;
  handleSubmit: () => void;
  isLoading: boolean;
  // Workaround until SearchInput is supported in Web DLS.
  // Need to use with new propname as reusing inputMode and ommitting from TextInputProps breaks the conditional type
  extendedInputMode: TextInputProps['inputMode'] | 'search';
};

export const TextInputWithIcon = ({
  icon,
  inputRef,
  inputMode,
  isLoading,
  extendedInputMode,
  handleSubmit,
  onFocus,
  ...props
}: TextInputWithIconProps) => {
  const track = useTracker();
  const translate = useSharedComponentsTranslate();
  const trackClick = () => {
    track.customEvent({
      eventAction: 'input field|click',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: `search: ${props.value}`,
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      trackClick();
      return handleSubmit();
    }
  };
  // Workaround until SearchInput is supported in Web DLS.
  // Want to put on search role for a11y, and role is not supported by textinput compponet.
  const roleProp = { role: 'search' } as any;

  return (
    <TextInput
      {...props}
      inputMode={(extendedInputMode as any) || inputMode}
      contentAfter={
        isLoading ? (
          <progress aria-label="Loading" className="spinner w-16" />
        ) : (
          <IconButton
            icon={icon}
            onClick={handleSubmit}
            aria-label={
              translate('RetailerSelector.search.searchButtonAriaLabel') ||
              'Search and continue to the filtered results'
            }
            variant="clear"
            data-testid="dealer:searchButton"
          />
        )
      }
      id="address-input"
      ref={inputRef}
      onKeyDown={handleKeyDown}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        trackClick();
        onFocus && onFocus(e);
      }}
      data-testid="dealer:search"
      {...roleProp}
    />
  );
};
