import { memo, useState } from 'react';
import { SubmitButton } from '@volvo-cars/react-forms';
import { useSelectorSettings } from 'src/hooks';
import { useTranslate } from 'src/providers';
import { isEqualRetailer } from 'src/utils/isEqualRetailer';
import { Retailer } from '@vcc-package/retailer-selector';

const RetailerSelectorCardUI = memo(function UI({
  retailer,
  wrapperRef: ref,
  selectedRetailer,
  handleRetailerClick,
}: {
  retailer: Retailer;
  wrapperRef: React.RefObject<HTMLDivElement>;
  selectedRetailer: Retailer | null;
  handleRetailerClick: any;
}) {
  const { name, addressLine1, addressLine2 } = retailer;
  const translate = useTranslate();
  const selectorSettings = useSelectorSettings();
  const isSelectedRetailer = isEqualRetailer(retailer, selectedRetailer);
  const [loading, setLoading] = useState(false);

  const handleRetailerSelect = () => {
    setLoading(true);
    selectorSettings.onRetailerSelect(retailer);
  };

  return (
    <div
      ref={ref}
      className="p-24 rounded border-ring mb-24 dl-hover:border-accent-blue dl-current:border-ring-2 flex-col gap-16"
      data-testid="dealer:retailerContainer"
      aria-current={isSelectedRetailer}
      role="button"
      tabIndex={0}
      onKeyDown={handleRetailerClick}
      onClick={handleRetailerClick}
    >
      <p
        className="font-medium"
        data-testid="dealer:name"
        aria-label={
          translate('global.retailer.retailerNameAriaLabel', {
            retailerName: name,
          }) || `${name} Click to view on map`
        }
      >
        {name}
      </p>
      <div>
        <p className="text-secondary pb-8" data-testid="dealer:address">
          {addressLine1} <br /> {addressLine2}
        </p>
      </div>
      <div>
        <SubmitButton
          variant={isSelectedRetailer ? 'filled' : 'outlined'}
          onClick={handleRetailerSelect}
          loading={loading}
          size="small"
        >
          {translate('global.retailer.selectRetailer') || 'Select retailer'}
        </SubmitButton>
      </div>
    </div>
  );
});

export default RetailerSelectorCardUI;
