import React from 'react';
import { Track } from '@volvo-cars/tracking';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import styles from './RetailerCard.Address.module.css';

type AddressProps = {
  ariaLabelRetailerName: string;
  url?: string;
  addressLines: string[];
};

export const Address = ({
  ariaLabelRetailerName,
  url,
  addressLines,
}: AddressProps) => {
  const translate = useSharedComponentsTranslate();
  const addresses = (
    <>
      {addressLines.map((line) => (
        <span key={line}>
          {line} <br />
        </span>
      ))}
    </>
  );

  return (
    <>
      {addressLines.length > 0 && (
        <Track eventLabel="driving instructions">
          <div
            className={`${styles.address} overflow-hidden whitespace-normal`}
          >
            {url ? (
              <a
                className={`${styles.addressLink} link-plain w-full block`}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                aria-label={
                  translate(
                    'RetailerSelector.retailerCard.getDirectionsAriaLabel',
                    {
                      retailerName: ariaLabelRetailerName,
                    },
                  ) || `Get directions ${ariaLabelRetailerName}`
                }
              >
                {addresses}
              </a>
            ) : (
              <p>{addresses}</p>
            )}
          </div>
        </Track>
      )}
    </>
  );
};
