import { logError } from 'src/utils/logError';
import { ErrorMessages } from 'src/constants/errorMessages';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import { Retailer } from '@vcc-package/retailer-selector';

type ExpandedCardDialogProps = {
  onClose: () => void;
  dialogProps: { ref: React.RefObject<HTMLDialogElement> };
  retailer: Retailer | null;
};

const ExpandedCardDialog = ({
  onClose,
  retailer,
  dialogProps,
}: ExpandedCardDialogProps) => {
  const titleId = 'expanded-card-title';
  if (!retailer && dialogProps.ref.current?.open) {
    logError(
      `${ErrorMessages.EXPANDED_CARD_SHEET_INVALID_RETAILER}: retailer is ${retailer}`,
    );
  }
  return (
    <dialog
      className="dialog-large w-sm md:mt-48"
      aria-labelledby={titleId}
      {...dialogProps}
    >
      {retailer && (
        <>
          <header slot="header">
            <CardHeader onClose={onClose} />
          </header>
          <article slot="main">
            <CardContent retailer={retailer} />
          </article>
        </>
      )}
    </dialog>
  );
};

export default ExpandedCardDialog;
