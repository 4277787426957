import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { translateCapability } from '../../utils/translateCapability';
import { Capability } from '../types/retailerCapabilities';

type CapabilityPillsProps = { capabilities: Capability[] };

export const CapabilityPills = ({ capabilities }: CapabilityPillsProps) => {
  const translate = useSharedComponentsTranslate();
  const redesignCapabilities = capabilities.filter((cap) => cap !== 'selekt');
  /* TODO When going live - selekt is temporary, should be removed from capabilites */

  return (
    <div className="flex gap-8 flex-wrap">
      {redesignCapabilities.map((capability) => (
        <div
          key={capability}
          data-testid={`dealer:${capability}-pill`}
          className="micro rounded-full py-2 px-8 w-fit border-ring whitespace-nowrap"
        >
          {translateCapability(capability, translate)}{' '}
        </div>
      ))}
    </div>
  );
};
