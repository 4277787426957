import { Capability } from '../src/types/retailerCapabilities';
import { logError } from '../src/logError';
import { ErrorMessages } from '../errorMessages';
import { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';

export const translateCapability = (
  capability: Capability,
  translate: (itemKey: keyof DictionaryItemTypes) => string,
) => {
  let exhaustiveCheck: never;
  switch (capability) {
    case 'service':
      return (
        translate('RetailerSelector.capabilities.serviceCapability') ||
        'Service'
      );
    case 'used car sales':
      return (
        translate('RetailerSelector.capabilities.usedCarSalesCapability') ||
        'Used car sales'
      );
    case 'sales':
      return (
        translate('RetailerSelector.capabilities.salesCapability') || 'Sales'
      );
    case 'collision center':
      return (
        translate('RetailerSelector.capabilities.collisionCenterCapability') ||
        'Collision center'
      );
    case 'selekt':
      return (
        translate('RetailerSelector.capabilities.selektCapability') || 'Selekt'
      );
    case 'diplomat':
      return (
        translate('RetailerSelector.capabilities.diplomatCapability') ||
        'Diplomat'
      );
    case 'test drive':
      return (
        translate('RetailerSelector.capabilities.testDriveCapability') ||
        'Test drive'
      );
    default:
      // Produces type error if Capability is not added in switch
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      exhaustiveCheck = capability;
      logError(ErrorMessages.INVALID_CAPABILITY);
      return '';
  }
};
