import React, { memo } from 'react';
import {
  RetailerCard,
  Retailer,
  useRetailerCardInfo,
  getRetailerLinks,
} from '@vcc-package/retailer-selector';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { getDealerId } from 'src/utils/volvoTrackingHelpers';
import { getValidSiteSlug } from 'src/utils/getValidSiteSlug';
import { useStore } from 'src/hooks';

type CardContentProps = { retailer: Retailer };

const CardContent = ({ retailer }: CardContentProps) => {
  const { siteSlug } = useCurrentMarketSite();
  const { firstDayOfWeek } = useStore();
  const { distance, addressHref, addressLines, name } =
    useRetailerCardInfo(retailer);
  const validSiteSlug = getValidSiteSlug(siteSlug);
  const links = getRetailerLinks(retailer, validSiteSlug);

  return (
    <div data-testid="dealer:retailerContainer">
      <div className="flex-col gap-24">
        <RetailerCard.Header name={name} distance={distance} />
        <RetailerCard.Address
          ariaLabelRetailerName={name}
          url={addressHref}
          addressLines={addressLines}
        />
        <RetailerCard.CapabilityPills capabilities={retailer.capabilities} />
        <RetailerCard.CapabilitySelection
          {...retailer}
          firstDayOfWeek={firstDayOfWeek}
        />
      </div>
      {links.length > 0 && (
        <>
          <div className="border-ornament border-b h-24" />
          <RetailerCard.LinksGroup
            dealerId={getDealerId(retailer)}
            dealerInfo={name}
            links={links}
          />
        </>
      )}
    </div>
  );
};
export default memo(CardContent);
