import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact, {
  Coords,
  ChangeEventValue as MapChangeEventValue,
  MapOptions,
} from 'google-map-react';
import { createRoot, Root } from 'react-dom/client';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import type { SiteSlug } from '@volvo-cars/market-sites';
import { MapControls } from 'src/features';
import { GOOGLE_CLIENT_ID } from 'src/constants/gql';
import { useOffset, useRetailers, useStore } from 'src/hooks';
import {
  DEFAULT_SELECTED_RETAILER_ZOOM,
  MIN_ZOOM_LEVEL,
  roundLocation,
  updateMapPanBoundsZoom,
} from 'src/utils/mapUtils';
import { DistanceUnit } from 'src/utils/formatDistance';
import { useClusters } from '../useClusters';
import Pin from './Pin';
import { Retailer, useMarketConfig } from '@vcc-package/retailer-selector';

// used to limit the zoom level on the map on initial page load by excluding retailers outside of a boundary
const initialRetailerLimits: {
  [key in SiteSlug]?: {
    minLatitude?: number;
    maxLatitude?: number;
    minLongitude?: number;
    maxLongitude?: number;
  };
} = {
  fr: {
    minLatitude: 30,
  },
  us: { minLongitude: -123, maxLongitude: 0 },
};
function getInitialRetailers(siteSlug: SiteSlug, retailers: Retailer[]) {
  const currentMarketRetailerLimit = initialRetailerLimits[siteSlug];
  if (!retailers || !currentMarketRetailerLimit) return retailers;
  const {
    minLatitude = -Infinity,
    maxLatitude = Infinity,
    minLongitude = -Infinity,
    maxLongitude = Infinity,
  } = currentMarketRetailerLimit;
  return retailers
    .filter(
      (retailer) =>
        retailer?.latitude &&
        Number(retailer.latitude) >= minLatitude &&
        Number(retailer.latitude) <= maxLatitude,
    )
    .filter(
      (retailer) =>
        retailer?.longitude &&
        Number(retailer.longitude) >= minLongitude &&
        Number(retailer.longitude) <= maxLongitude,
    );
}
export const Map = (): JSX.Element => {
  const { marketName, locale, regionCode, siteSlug, roadLengthUnit } =
    useCurrentMarketSite();
  const [markersVisibility, setMarkersVisibility] = useState(true);
  const [userLocation, setUserLocation] = useState<Coords | null>(null);
  const [latestMapChangeEventValue, setLatestMapChangeEventValue] =
    useState<MapChangeEventValue>();
  const { dispatch, selectedRetailer, map, address } = useStore();
  const { retailers } = useRetailers();
  const { mapReboundMaxRadius1000km } = useMarketConfig();
  const maxRadius = mapReboundMaxRadius1000km ? 1000 : -1;
  const clusters = useClusters(latestMapChangeEventValue, retailers);
  const isIntlMarket = marketName === 'International';
  const offset = useOffset();
  useEffect(() => {
    if (isIntlMarket) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { longitude, latitude } = position.coords;
        setUserLocation({ lng: longitude, lat: latitude });
      });
    }
  }, [isIntlMarket]);

  useEffect(() => {
    if (!retailers || !retailers.length || !map) return;
    setMarkersVisibility(false);
    const bounds = new google.maps.LatLngBounds();
    const hasMaxRadius = !!maxRadius && maxRadius !== -1;
    const shownRetailers = address
      ? hasMaxRadius
        ? getMaxRadiusFilteredRetailers(
            retailers.slice(0, 3),
            maxRadius,
            roadLengthUnit,
          )
        : retailers.slice(0, 3)
      : getInitialRetailers(siteSlug as SiteSlug, retailers);

    if (!selectedRetailer && shownRetailers && !userLocation) {
      shownRetailers.forEach((retailer: Retailer) => {
        bounds.extend({
          lat: parseFloat(retailer.latitude),
          lng: parseFloat(retailer.longitude),
        });
      });

      // Add some "zoom padding" if only one retailer was found, else let the bounds to the job
      const zoom =
        shownRetailers.length === 1
          ? DEFAULT_SELECTED_RETAILER_ZOOM
          : undefined;
      const center = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      };
      updateMapPanBoundsZoom(map, { ...center, bounds, zoom, offset }, 50);
    }

    if (userLocation && !selectedRetailer) {
      updateMapPanBoundsZoom(
        map,
        {
          lng: roundLocation(userLocation.lng),
          lat: roundLocation(userLocation.lat),
          zoom: MIN_ZOOM_LEVEL,
          offset,
        },
        50,
      );
    }

    if (selectedRetailer) {
      updateMapPanBoundsZoom(map, {
        lat: parseFloat(selectedRetailer.latitude),
        lng: parseFloat(selectedRetailer.longitude),
        zoom: DEFAULT_SELECTED_RETAILER_ZOOM,
        offset,
      });
    }
    const markersVisibilityCallback = () => {
      setMarkersVisibility(true);
    };
    const idleListener = google.maps.event.addListener(
      map,
      'idle',
      markersVisibilityCallback,
    );
    return () => {
      google.maps.event.removeListener(idleListener);
      setMarkersVisibility(false);
    };
  }, [
    map,
    maxRadius,
    retailers,
    address,
    selectedRetailer,
    siteSlug,
    roadLengthUnit,
    isIntlMarket,
    userLocation,
    offset,
  ]);

  const mapControlsRoot = useRef<Root | null>(null);

  const handleApiLoaded = (
    map: google.maps.Map | null,
    maps: typeof google.maps,
  ) => {
    dispatch({ type: 'SET_MAP', payload: map });
    const controlButtonDiv = document.createElement('div');
    if (!map) return;
    const root = mapControlsRoot.current || createRoot(controlButtonDiv);
    mapControlsRoot.current = root;
    root.render(<MapControls map={map} />);
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
    if (retailers?.length) return;
    const geocoder = new maps.Geocoder();
    !isIntlMarket &&
      geocoder.geocode({ address: marketName }, (results, status) => {
        if (status === maps.GeocoderStatus.OK && results) {
          const { lat, lng } = results[0].geometry.location;
          const bounds = results[0].geometry.viewport;

          updateMapPanBoundsZoom(map, {
            lat: lat(),
            lng: lng(),
            bounds,
            zoom: 5,
          });

          const location = results[0].geometry.location.toJSON();
          dispatch({
            type: 'SET_SITE_LOCATION',
            payload: [location.lat, location.lng],
          });
        }
      });
  };
  return (
    <div
      className="h-full w-full"
      /* extend={containerCSS(untilLHeight)} */
      data-testid="dealer:mapViewContainer"
    >
      <GoogleMapReact
        bootstrapURLKeys={
          {
            client: GOOGLE_CLIENT_ID,
            v: '3.44',
            language: locale,
            region: regionCode,
          } as any
        }
        defaultCenter={{ lat: 0, lng: 0 }}
        options={createGoogleMapOptions({
          isIntl: siteSlug === 'intl',
        })}
        defaultZoom={5}
        onChange={setLatestMapChangeEventValue}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {clusters.map((cluster) => (
          <Pin
            pinVisibility={markersVisibility}
            key={cluster.key}
            lng={cluster.geometry.coordinates?.[0]}
            lat={cluster.geometry.coordinates?.[1]}
            map={map}
            cluster={cluster}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

/** Returns array containing only retailers within the given maxRadius */
const getMaxRadiusFilteredRetailers = (
  retailers: Retailer[],
  maxRadius: number,
  distanceUnit: DistanceUnit,
): Retailer[] => {
  return retailers.filter((retailer, index) => {
    const distance =
      distanceUnit === 'mile'
        ? retailer.distanceFromPointMiles ?? 0
        : retailer.distanceFromPointKm ?? 0;
    return index === 0 || distance < maxRadius;
  });
};

const createGoogleMapOptions = ({
  isIntl,
}: {
  isIntl: boolean;
}): MapOptions => {
  const mapOptions: MapOptions = {
    gestureHandling: 'greedy',
    disableDefaultUI: true,
  };

  // https://github.com/google-map-react/google-map-react/blob/master/API.md#override-the-default-minimum-zoom
  // Override default minimum zoom only for intl. As mentioned above,
  // minZoom is buggy and ruins the marker calculation when mixed with restriction
  if (isIntl) {
    mapOptions.minZoom = MIN_ZOOM_LEVEL;
  }
  return mapOptions;
};
