import React from 'react';
import Link from 'next/link';
import { Icon } from '@volvo-cars/react-icons';
import { Lead, Track, TrackingProvider } from '@volvo-cars/tracking';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { RetailerLink } from '../types/links';
import { TRACKING_CATEGORY } from '../../tracking';

export const LinksGroup = ({
  dealerId,
  dealerInfo,
  links,
}: Lead & { links: RetailerLink[] }) => {
  const translate = useSharedComponentsTranslate();

  return (
    <TrackingProvider eventCategory={TRACKING_CATEGORY}>
      <div className="flex-col gap-x-24 mt-8 w-full">
        {links.map(({ url, translationKey, eventLabel, defaultLabel }) => (
          <Track
            eventLabel={eventLabel}
            key={eventLabel}
            customData={{
              dealerId,
              dealerInfo,
            }}
          >
            <Link
              href={url}
              target="_blank"
              className="py-16 text-accent-blue hover:text-primary"
            >
              <div className="flex items-center justify-between">
                <p className="font-medium">
                  {translate(translationKey) || defaultLabel}
                </p>
                <Icon icon="chevron-forward" size={12} color="accent-blue" />
              </div>
            </Link>
          </Track>
        ))}
      </div>
    </TrackingProvider>
  );
};
