import React from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { RetailerCard } from './RetailerCard';
import { Retailer } from '../types/retailer';
import { translateCapability } from '../../utils/translateCapability';
import {
  SelectionCapabilities,
  useCapabilitySelection,
} from '../hooks/useCapabilitySelection';

type CapabilitySelectionProps = {
  openingHours: Retailer['openingHours'];
  phoneNumbers: Retailer['phoneNumbers'];
  capabilities: Retailer['capabilities'];
  firstDayOfWeek?: 'monday' | 'sunday';
  name: Retailer['name'];
};

export const CapabilitySelection = ({
  openingHours,
  phoneNumbers,
  capabilities,
  firstDayOfWeek = 'monday',
  name,
}: CapabilitySelectionProps) => {
  const translate = useSharedComponentsTranslate();
  const { locale } = useCurrentMarketSite();
  const defaultCapabilityIndex = 0;
  const tabCapabilities: SelectionCapabilities[] = ['sales', 'service'];
  const selectionCapabilites = tabCapabilities.filter((c) =>
    capabilities.includes(c),
  );
  const {
    setSelectedCapability,
    selectedOpeningHours,
    selectedPhoneNumber,
    selectedCapability,
  } = useCapabilitySelection({
    openingHours,
    phoneNumbers,
    defaultCapability: selectionCapabilites.at(defaultCapabilityIndex),
  });

  return selectionCapabilites.length > 0 ? (
    <>
      {selectionCapabilites.length > 1 && (
        <div className="flex justify-center">
          <RetailerCard.CapabilityTabs
            capabilities={selectionCapabilites}
            defaultSelectedIndex={defaultCapabilityIndex}
            onCapabilityChanged={setSelectedCapability}
          />
        </div>
      )}
      {selectedCapability && (selectedOpeningHours || selectedPhoneNumber) && (
        <>
          <div className="flex justify-between font-medium">
            <p>{translateCapability(selectedCapability, translate)}</p>

            <RetailerCard.PhoneNumber
              ariaLabelRetailerName={name}
              phoneNumber={selectedPhoneNumber}
              isClickable
            />
          </div>
          {selectedOpeningHours && (
            <RetailerCard.OpeningHours
              openingHours={selectedOpeningHours}
              firstDayOfWeek={firstDayOfWeek}
              locale={locale}
            />
          )}
        </>
      )}
    </>
  ) : (
    <RetailerCard.PhoneNumber
      ariaLabelRetailerName={name}
      phoneNumber={phoneNumbers.main}
    />
  );
};
