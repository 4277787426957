import React from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';

type FilterButtonProps = {
  nbrActiveFilters?: number;
  onClick: () => void;
};
export const FilterButton = ({
  nbrActiveFilters,
  onClick,
}: FilterButtonProps) => {
  const translate = useSharedComponentsTranslate();
  return (
    <button
      type="button"
      className="button-filled p-16 gap-8"
      aria-label={
        translate('RetailerSelector.search.filterButtonAriaLabel') || 'Filter'
      }
      onClick={onClick}
    >
      <Icon icon="filter" filled color="always-white" size={16} alt="" />
      {nbrActiveFilters}
    </button>
  );
};
