import { AutocompleteResponse } from '../src/types/maps';

/**
 *
 * the usage of [...desc] is a result of the javascript String.prototype.slice() function not being unicode-aware.
 * The string interator however, is uncode aware, so we spread the strings into string[] before splicing and
 * use reduce to create a single string before pushing the result to the return datastructure.
 */
export function parsePrediction(
  prediction: AutocompleteResponse['data'][number],
) {
  const desc = prediction?.structured_formatting.main_text;
  const substrs = prediction.structured_formatting.main_text_matched_substrings;
  const result = [];
  let lastOffset = 0;
  substrs?.forEach((substr: (typeof substrs)[number]) => {
    const prev = [...desc].slice(lastOffset, substr.offset);
    if (prev.length) {
      result.push({ text: prev.reduce((p, c) => p + c, ''), type: 'normal' });
      lastOffset = substr.offset;
    }
    const curr = [...desc].slice(substr.offset, substr.length + substr.offset);
    if (curr.length) {
      result.push({ text: curr.reduce((p, c) => p + c, ''), type: 'bold' });
      lastOffset = substr.offset + substr.length;
    }
  });
  if (desc.length - lastOffset) {
    result.push({
      text: [...desc]
        .slice(lastOffset, desc.length)
        .reduce((p, c) => p + c, ''),
      type: 'normal',
    });
  }
  return result;
}
