import React from 'react';

type CtaProps = {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Cta = ({ label, onClick }: CtaProps) => {
  return (
    <div className="border-t border-ornament flex-col items-center">
      <button className="button-outlined mt-24" type="button" onClick={onClick}>
        {label}
      </button>
    </div>
  );
};
