import React, { ReactNode, useMemo } from 'react';
import { IconButton } from '@volvo-cars/react-icons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';

type NoResultTextProps = {
  handleLocationClick: () => void;
  handleClose: () => void;
};

export const NoResultText = ({
  handleLocationClick,
  handleClose,
}: NoResultTextProps): JSX.Element => {
  const translate = useSharedComponentsTranslate();

  const linkWrapper = useMemo(
    () => ({
      a: function aTag(link: ReactNode) {
        return (
          <button
            className="text-secondary link-inline"
            data-testid="dealer:useYourLocation"
            aria-label={
              translate('RetailerSelector.search.helpTextLinkAriaLabel') ||
              'Click to search near your location'
            }
            onClick={handleLocationClick}
            type="button"
            tabIndex={-1}
          >
            {link}
          </button>
        );
      },
    }),
    [handleLocationClick, translate],
  );

  return (
    <div
      className="p-16 contain-content pr-48 flex-col flex-wrap gap-8 border-l-2 border-feedback-orange bg-feedback-orange"
      data-testid="dealer:noResultsText"
    >
      <p>
        {translate('RetailerSelector.search.noRetailersFound') ||
          'No retailers found.'}
      </p>
      <p>
        {translate('RetailerSelector.search.trySearchLocation', linkWrapper) ||
          'Try searching by your location, address, or for retailers near you'}
      </p>
      <IconButton
        aria-label={translate('RetailerSelector.search.close') || 'Close'}
        className="absolute top-8 end-8"
        icon="x"
        variant="clear"
        onClick={handleClose}
      />
    </div>
  );
};
