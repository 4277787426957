import * as React from 'react';
import { Retailer } from '../types/retailer';

type RetailerListProps = {
  listRef: React.RefObject<HTMLUListElement>;
  retailers: Retailer[];
  retailerCardRender: (retailer: Retailer) => JSX.Element;
};

export const RetailerList = ({
  listRef,
  retailers,
  retailerCardRender,
}: RetailerListProps) => {
  return (
    <ul
      className="flex-col gap-16 px-24 lg:px-0 xl:px-0 relative"
      ref={listRef}
    >
      {retailers.map((retailer) => (
        <li
          key={retailer.parmaPartnerCode || '' + retailer.distanceFromPointKm}
        >
          {retailerCardRender(retailer)}
        </li>
      ))}
    </ul>
  );
};
