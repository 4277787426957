import { useState } from 'react';
import { Track } from '@volvo-cars/tracking';
import { useDialog } from '@volvo-cars/react-headless';
import { useRetailers } from 'src/hooks';
import FilterSheet from './FilterSheet';
import { FilterButton as SharedFilterButton } from '@vcc-package/retailer-selector';

const FilterButton = () => {
  const [reset, setReset] = useState(false);
  const onToggle = () => {
    setReset((prev) => !prev);
  };
  const { dialogProps, showDialog, closeDialog } = useDialog({ onToggle });
  const { activeCapabilityFilters } = useRetailers();
  return (
    <>
      <Track eventLabel="open-filters">
        <SharedFilterButton
          nbrActiveFilters={activeCapabilityFilters.length || undefined}
          onClick={showDialog}
        />
      </Track>
      <FilterSheet
        onClose={closeDialog}
        dialogProps={dialogProps}
        resetToggle={reset}
      />
    </>
  );
};

export default FilterButton;
