import { logError } from '../../logError';
import { ErrorMessages } from '../../../errorMessages';

export type DistanceUnit = 'mile' | 'kilometer';

export const formatDistance = (
  distance: number,
  locale: string,
  unit: DistanceUnit,
) => {
  const formattedUnit = unit === 'mile' ? 'mi' : 'km';
  let formattedDistance: string;
  try {
    formattedDistance = distance.toLocaleString(locale, {
      style: 'unit',
      unit,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
  } catch (e) {
    formattedDistance = `${Math.round(distance).toString()} ${formattedUnit}`;
    logError(
      `${ErrorMessages.FORMAT_DISTANCE_INVALID_LOCALE}: for locale ${locale}, distance ${distance} and unit ${unit}, with error ${e}`,
    );
  }
  return `${formattedDistance}`;
};
