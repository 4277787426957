import React, { useEffect, useRef } from 'react';
import { Header } from './RetailerCard.Header';
import { Address } from './RetailerCard.Address';
import { PhoneNumber } from './RetailerCard.PhoneNumber';
import { Cta } from './RetailerCard.Cta';
import { OpeningHours } from './RetailerCard.OpeningHours';
import { TapArea } from './RetailerCard.TapArea';
import { CapabilityPills } from './RetailerCard.CapabilityPills';
import { CapabilityTabs } from './RetailerCard.CapabilityTabs';
import { LinksGroup } from './RetailerCard.LinksGroup';
import { CapabilitySelection } from './RetailerCard.CapabilitySelection';

type RetailerCardProps = React.PropsWithChildren<{
  isSelected?: boolean;
  scrollProps?: {
    scrollToCard: (ref: React.RefObject<HTMLDivElement>) => void;
  };
}>;

export const RetailerCard = ({
  children,
  isSelected = false,
  scrollProps,
}: RetailerCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected && scrollProps) {
      scrollProps?.scrollToCard(ref);
    }
  }, [isSelected, scrollProps]);

  return (
    <div
      ref={ref}
      data-testid="dealer:miniCard"
      className="bg-always-white flex-col p-24 gap-16 rounded border border-ornament current:border-accent-blue"
      aria-current={isSelected}
    >
      {children}
    </div>
  );
};

RetailerCard.Header = Header;
RetailerCard.Address = Address;
RetailerCard.PhoneNumber = PhoneNumber;
RetailerCard.Cta = Cta;
RetailerCard.OpeningHours = OpeningHours;
RetailerCard.TapArea = TapArea;
RetailerCard.CapabilityPills = CapabilityPills;
RetailerCard.CapabilityTabs = CapabilityTabs;
RetailerCard.LinksGroup = LinksGroup;
RetailerCard.CapabilitySelection = CapabilitySelection;
